<!-- 错题本 -->
<template>
  <div class="box">
    <el-scrollbar style="height: 100%">
      <el-card class="box-card">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="请选择班级" prop="classRoom">
            <el-select
              v-model="ruleForm.classRoom"
              placeholder="请选择班级"
              size="mini"
              ref="nationalityIdSel"
              @change="changeClass"
            >
              <el-option
                v-for="item in classOptions"
                :key="item.id"
                :label="item.className"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择学生" prop="student">
            <el-select
              v-model="ruleForm.student"
              placeholder="请选择学生"
              size="mini"
              ref="studentContent"
              multiple
              collapse-tags
              clearable
              @change="selectStudent"
            >
              <el-option
                v-for="item in studentOptions"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择题集" prop="classActive">
            <el-cascader
              v-model="ruleForm.classActive"
              :options="questionOptions"
              @change="changeActive"
              @visible-change="elCascaderOnlick"
              @expand-change="elCascaderOnlick"
              :props="props"
              collapse-tags
              clearable
              size="mini"
            ></el-cascader>
            <el-button type="primary" size="mini" @click="All('All')">全选</el-button>
            <el-button type="primary" size="mini" @click="wrongQuery('ruleForm')">查询错题</el-button>
            <!-- <el-button type="primary" size="mini" @click="savePdf" :disabled="showExport">导出为PDF</el-button> -->
            <el-button
              class="btn"
              size="mini"
              type="primary"
              data-clipboard-target="#exportPdf"
              data-clipboard-action="copy"
              @click="copy"
              :disabled="showExport"
            >复制</el-button>
            <el-popover
              placement="bottom"
              title="小提示"
              width="200"
              trigger="click"
              style="height:150px"
            >
              <p>1.为缩减数据量,建议日期选择需要查询的时期区间以便提高查询速度</p>
              <p>2.查询出结果后可以一键复制到word文档中,并在布局中的纸张方向中选择横向,纸张大小中选择A3纸张,以便更好的预览</p>
              <span
                slot="reference"
                class="span_tip el-icon-question"
                style="display:inline-block;margin-left:10px;"
              ></span>
            </el-popover>
          </el-form-item>
          <br />
          <el-form-item label="开始日期：" class="block">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.startDate"
              type="date"
              placeholder="选择日期"
              size="mini"
              style="width: 193px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期：" prop="endDate" class="block">
            <el-date-picker
              style="width: 193px"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.endDate"
              type="date"
              placeholder="选择日期"
              size="mini"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <el-card class="box-card-rigth">
          <div class="wrong-box-card" id="exportPdf" ref="exportPdf" v-if="!showExport">
            <span class="wrong-question">{{ wrongTitle }}</span>
            <el-divider></el-divider>
            <div class="wrong-details">
              <span>学习中心：暂无信息</span>
              <span>班级： {{ wrongClassRoom }}</span>
              <span>统计时间： {{ wrongTime }}</span>
            </div>
            <!-- 内容 -->
            <div v-loading="loading" element-loading-text="正在统计错题">
              <div class="wrong-table wrong-table-A" v-if="!showEmpty">
                <div v-for="(table_item,table_index) in tatleMore" :key="table_index">
                  <el-divider>{{table_item.name+'的错题簿'}}</el-divider>
                  <table
                    border="1px"
                    v-for="(item, index) in table_item.tatleMore"
                    :key="index"
                    style="margin-bottom: 40px"
                  >
                    <tr style="background-color: rgb(173, 216, 230)">
                      <td>学生：</td>
                      <td colspan="1">{{ item.fullName }}</td>
                      <td>变式：</td>
                      <td colspan="1">{{ item.variantName }}</td>
                      <td>任务时间：</td>
                      <td colspan="1">{{ item.time || "暂无数据" }}</td>
                    </tr>
                    <tr>
                      <td>题干：</td>
                      <td colspan="5" v-html="item.topicContent?item.topicContent:'暂无题干'"></td>
                    </tr>
                    <tr v-for="(itm, index) in item.tableData" :key="index">
                      <td colspan="6" v-if="itm.number !== 0">
                        <table style="width: 100%">
                          <tr class="wrong-information" v-if="itm.number !== 0">
                            <td>考察方向：{{ itm.subject }}</td>
                            <td>题型：{{ questionType[itm.tableRadio] }}</td>
                            <td
                              class="wrong-information-two"
                              v-if="itm.number !== 0"
                              style="color: red"
                            >选择情况：选错{{ itm.number }}处</td>
                            <td v-if="itm.number == 0" style="color: lightgreen">完全正确</td>
                            <td>次数：第{{itm.count}}次</td>
                            <td>答题时间：{{itm.createTime}}</td>
                          </tr>
                          <tr>
                            <td colspan="6" class="td-content">
                              <table style="width: 100%">
                                <tr>
                                  <td colspan="5" v-if="itm.number !== 0">本题题目解读，正确的是？</td>
                                  <td v-if="itm.number !== 0">正确答案</td>
                                  <td v-if="itm.number !== 0">你的选择</td>
                                </tr>
                                <tr v-for="(it, index) in itm.optionData" :key="index">
                                  <td style="width: 20px">{{ it.optionSN }}</td>
                                  <td colspan="4" v-html="it.option"></td>
                                  <td
                                    :class="
                                    it.isCorrect !== it.Np
                                      ? 'css_option_no'
                                      : 'css_option'
                                  "
                                    style="text-align: center"
                                  >{{ moreOption[it.isCorrect] }}</td>
                                  <td
                                    :class="
                                    it.isCorrect !== it.Np
                                      ? 'css_option_no'
                                      : 'css_option'
                                  "
                                    style="text-align: center"
                                  >{{ moreOption[it.Np] }}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <el-backtop target=".wrong-table">
                <i class="el-icon-caret-top"></i>
              </el-backtop>
              <!-- <el-pagination
                v-if="!showEmpty"
                background
                style="margin-bottom: 70px"
                layout="prev, pager, next"
                :current-page="specialPaging"
                :page-size="10"
                :total="specialtotal"
                @current-change="changespecialPaging"
                class="pager"
              ></el-pagination>-->
            </div>
            <el-empty description="暂未查询到错题！" v-if="showEmpty"></el-empty>
          </div>
          <exportPDF
            :AllTable="AllTable"
            :wrongClassRoom="wrongClassRoom"
            :wrongTime="wrongTime"
            :show_loading="show_loading"
          ></exportPDF>
        </el-card>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { classList } from "../../api/api";
import { GetStudentByGradeClass } from "../../api/api";
import { mistakesList } from "../../api/api";
import { WrongTopic, mistakesExport } from "../../api/api";
import exportPDF from './components/exportPDF.vue'
export default {
  data () {
    let validateStartTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择开始时间"));
      } else {
        if (this.ruleForm.endDate) {
          this.$refs.ruleForm.validateField("endDate");
        }
        callback();
      }
    };
    let validateEndTime = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!this.ruleForm.startDate) {
          callback(new Error("请选择开始时间！"));
        } else if (Date.parse(this.ruleForm.startDate) >= Date.parse(value)) {
          callback(new Error("结束时间必须大于开始时间！"));
        } else {
          callback();
        }
      }
    };
    return {
      wrongTitle: "错题簿",
      showEmpty: false,
      tatleMore: [],
      wrongClassRoom: "",
      wrongTime: "",
      ruleForm: {
        classRoom: "",
        classActive: [],
        student: [],
        endDate: null,
        startDate: null,
      },
      rules: {
        classRoom: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        classActive: [
          { required: true, message: "请选择题集", trigger: "change" },
        ],
        student: [{ required: true, message: "请选择学生", trigger: "change" }],
        startDate: [
          { required: true, validator: validateStartTime, trigger: "blur" },
        ],
        endDate: [
          { required: false, validator: validateEndTime, trigger: "blur" },
        ],
      },
      moreOption: ["×", "√"],
      frequency: {
        1: "一次做错",
        2: "二次做错",
      },
      questionType: {
        2: "单选",
        3: "多选",
      },
      classOptions: [],
      questionOptions: [],
      studentOptions: [],
      showExport: true,
      props: { multiple: true },
      options: [],
      classId: null,
      loading: false,
      specialtotal: 0,
      specialPaging: 1,
      AllTable: [],//导出所有
      show_loading: false
    };
  },
  components: {
    exportPDF
  },
  mounted () {
    this.loadClass();
  },
  methods: {
    copy () {
      let clipboard = new Clipboard(".btn");
      clipboard.on("success", () => {
        this.$message.success('复制成功!请移步word进行粘贴!')
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.error('复制失败!')
        clipboard.destroy();
      });
    },
    // 设置点击文本选中
    elCascaderOnlick () {
      setTimeout(function () {
        document.querySelectorAll(".el-cascader-menu").forEach((el, index) => {
          if (index == 1) {
            el.querySelectorAll(".el-cascader-node__label").forEach((el1) => {
              el1.onclick = function () {
                this.previousElementSibling.click();
              };
            });
          }
        });
      }, 100);
    },
    //导出
    savePdf () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let arr = [];
          this.ruleForm.classActive.forEach((item) => {
            arr.push(item[1]);
          });

          let param = {
            classId: this.ruleForm.classRoom,
            user: this.ruleForm.student,
            ids: arr,
          };
          this.show_loading = true
          mistakesExport(param).then((res) => {
            this.show_loading = false
            // console.log(res)
            if (res.code == 0) {
              let arr = this.export_pdf(res.data.mistakesEntities, res.data.userOptionEntities)
              this.AllTable = arr;
            } else {
              this.$message.error(res.message)
            }
          });
        } else {
          return false;
        }
      });
      // this.$PDFSave(this.$refs.exportPdf, this.wrongTitle);
    },
    //选中
    All () {
      this.loadStudent();
    },
    wrongQuery (formName, type) {
      this.loading = true;
      this.tatleMore = [];
      this.showEmpty = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = [];
          this.ruleForm.classActive.forEach((item) => {
            arr.push(item[1]);
          });
          let param = {
            //需要加分页
            classId: this.ruleForm.classRoom,
            user: this.ruleForm.student,
            ids: arr,
            beginTime: this.ruleForm.startDate,
            endTime: this.ruleForm.endDate,
          };
          mistakesExport(param).then((res) => {
            this.loading = false;
            if (res.code == 0) {
              if (res.data.userOptionEntities.length !== 0) {
                let table_arr = []
                let arr = this.export_pdf(res.data.mistakesEntities, res.data.userOptionEntities)
                arr.forEach(item => {
                  let obj = {
                    name: null,
                    userId: null,
                    tatleMore: []
                  }
                  if (table_arr.length == 0) {
                    obj.userId = item.userId
                    obj.name = item.fullName
                    obj.tatleMore.push(item)
                    table_arr.push(obj)
                  } else {
                    let a = table_arr.find(find_item => { return item.userId == find_item.userId && item.fullName == find_item.name })
                    if (a) {
                      a.tatleMore.push(item)
                    } else {
                      obj.userId = item.userId
                      obj.name = item.fullName
                      obj.tatleMore.push(item)
                      table_arr.push(obj)
                    }
                  }
                })
                console.log(table_arr)
                this.tatleMore = table_arr;
              } else {
                this.showEmpty = true;
              }
              this.specialtotal = res.data.total;
            } else {
              this.showEmpty = true;
              this.$message.error(res.message);
            }
            let time = this.currentTime.currentTime(1);
            this.wrongTime = time;
            this.showExport = false;
          });
          this.showExport = false;
        } else {
          return false;
        }
      });
    },
    // 选择班级
    changeClass (val) {
      this.classId = val;
      this.ruleForm.classActive = [];
      this.ruleForm.student = [];
      this.loadStudent();
    },
    // 选择题集
    changeActive (val) {
      // this.loadStudent();
    },
    // 选择学生
    selectStudent (val) {
      this.ruleForm.classActive = [];
      let param = {
        classId: this.ruleForm.classRoom,
        user: this.ruleForm.student,
      };
      if (this.ruleForm.student.length <= 0) {
      } else {
        WrongTopic(param).then((res) => {
          if (res.code == 0) {
            res.data.map((item) => {
              item.value = item.setId;
              item.label = item.setName;
              if (item.npCases) {
                item.children = item.npCases;
                let obj = {};
                item.children = item.children.reduce(function (item, next) {
                  obj[next.caseId]
                    ? ""
                    : (obj[next.caseId] = true && item.push(next));
                  return item;
                }, []);
                item.children.map((itm, index) => {
                  itm.value = itm.caseId;
                  itm.label = itm.caseSN;
                  this.ruleForm.classActive.push([itm.setId, itm.caseId]);
                });
              }
            });
            this.questionOptions = res.data;
            this.wrongClassRoom = this.$refs.nationalityIdSel.selectedLabel;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    loadClass () {
      classList({}).then((res) => {
        if (res.code == 0) {
          this.classOptions = res.data.list;
        }
      });
    },
    loadStudent () {
      let that = this;
      that.ruleForm.student = [];
      let param = {
        classId: this.ruleForm.classRoom,
        isUser: 1,
      };
      GetStudentByGradeClass(param).then((res) => {
        if (res.code == 0) {
          res.data.list.map((item) => {
            item.label = item.fullName;
            item.value = item.id;
            that.ruleForm.student.push(item.id);
          });
          this.studentOptions = res.data.list;
          this.selectStudent();
        }
      });
    },
    //分页
    changespecialPaging (val) {
      this.specialPaging = val;
      this.wrongQuery("ruleForm");
    },
    export_pdf (list, userDate) {
      let arr = [];
      let last_arr = []
      let user_arr = []
      userDate.forEach(item => {
        let obj = {
          userId: null,
          caseId: null,
          fullName: null,
          obj_arr: [],
        }
        if (arr.length == 0) {
          obj.userId = item.userId
          obj.fullName = item.fullName
          obj.caseId = item.caseId
          obj.createTime = item.createTime
          obj.obj_arr.push(item)
          arr.push(obj)
        } else {
          let a = arr.find(find_item => { return item.userId == find_item.userId && item.caseId == find_item.caseId })
          if (a) {
            a.obj_arr.push(item)
          } else {
            obj.userId = item.userId
            obj.fullName = item.fullName
            obj.caseId = item.caseId
            obj.createTime = item.createTime
            obj.obj_arr.push(item)
            arr.push(obj)
          }
        }
      })
      // arr 拼接好的学生做题信息
      arr.map(item => {
        let obj = {
          fullName: null,
          variantName: null,
          time: null,
          topicContent: null,
          tableData: [],
          option: [],
          userId: null
        }
        let case_subject = list.find(find_item => { return find_item.caseId == item.caseId })
        if (case_subject) {
          obj.fullName = item.fullName
          obj.userId = item.userId
          obj.variantName = case_subject.variantName
          case_subject.descriptions.map((itm) => {
            if (itm.descriptionType == "6") {
              obj.topicContent = itm.description
            }
          });
          obj.option = case_subject.tableData
          obj.tableData = item.obj_arr
          obj.tableData.map(obj_item => {
            obj_item.optionData = []
            let zm_opt = []
            obj_item.optionNum = this.judgeOdevity(Number(obj_item.userAnswer))
            // 获取选项
            obj.option.map(option_item => {
              if (obj_item.questionId == option_item.id) {
                obj_item.subject = option_item.subject
                obj_item.tableRadio = option_item.tableRadio
                option_item.options.map((i) => {
                  zm_opt.push(i.optionSN)
                })
                obj_item.optionData = JSON.parse(JSON.stringify(option_item.options));
              }
            })
            obj_item.optionYes = this.letter(obj_item.optionNum, zm_opt)
            obj_item.optionData.sort(function (a, b) {
              return a.optionSN.localeCompare(b.optionSN);
            });

          })
          last_arr.push(obj)
        }
      })
      last_arr.map(item => {
        item.tableData.map((f_item, index) => {
          f_item.number = 0
          f_item.optionData.map(s_item => {
            if (f_item.optionYes.indexOf(s_item.optionSN) > -1) {
              s_item['Np'] = 1;
            } else {
              s_item['Np'] = 0;
            }
            if (s_item.isCorrect !== s_item.Np) {
              f_item.number++
            }
          })
        })
      })
      user_arr = last_arr
      // console.log("最后结合狗");
      // console.log(last_arr);
      return user_arr
    }
  },
};
</script>
<style lang='less' scoped>
@import '../../style/wrongBook/index.less';
@import '../../style/mouse.less';
.css_option {
  color: lightgreen;
  width: 100px;
  text-align: center;
}
.css_option_no {
  color: red;
  width: 100px;
  background-color: yellow;
  text-align: center;
}
.serial_option {
  width: 10px;
}
.span_tip {
  width: 20px;
  height: 20px;
  // background-color: #000;
  border-radius: 50%;
}
.el-icon-question {
  font-size: 20px;
}
</style>

<!-- 全部导出pdf -->
<template>
  <div v-show="fullscreenLoading">
    <div
      class="wrong-box-card"
      :id="`exportAllPdf${table_index}`"
      :ref="`exportAllPdf${table_index}`"
      v-for="(table_item,table_index) in table"
      :key="table_index"
    >
      <!-- v-for="(table_item,table_index) in table"
      :key="table_index"-->
      <!--:id="`exportAllPdf${table_index}`"
      :ref="`exportAllPdf${table_index}`"-->
      <!-- v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="正在导出中...."
      element-loading-background="rgba(0, 0, 0, 0.8)"-->
      <!--  -->
      <span class="wrong-question">{{ wrongTitle }}</span>
      <el-divider></el-divider>
      <div class="wrong-details">
        <span>学习中心：暂无信息</span>
        <span>班级： {{ allWrongClassRoom }}</span>
        <!-- <span>学生： {{wrongStudent}}</span> -->
        <span>统计时间： {{ allWrongTime }}</span>
      </div>
      <!-- 内容 -->
      <div>
        <div class="wrong-table">
          <table
            border="1px"
            v-for="(item, index) in table_item.tatleMore"
            :key="index"
            style="margin-bottom: 40px"
          >
            <!-- table_item.tatleMore -->
            <tr style="background-color: rgb(173, 216, 230)">
              <td>学生：</td>
              <td colspan="1">{{ item.fullName }}</td>
              <td>变式：</td>
              <td colspan="1">{{ item.variantName }}</td>
              <td>任务时间：</td>
              <td colspan="1">{{ item.time || "暂无数据" }}</td>
            </tr>
            <tr>
              <td>题干：</td>
              <td colspan="5" v-html="item.topicContent?item.topicContent:'暂无题干'"></td>
            </tr>
            <tr v-for="(itm, index) in item.tableData" :key="index">
              <td colspan="6" v-if="itm.number !== 0">
                <table style="width: 100%">
                  <tr class="wrong-information" v-if="itm.number !== 0">
                    <td>考察方向：</td>
                    <td class="wrong-information-two">{{ itm.subject }}</td>
                    <td>题型：</td>
                    <td class="wrong-information-two">{{ questionType[itm.tableRadio] }}</td>
                    <td class="wrong-information-two">选择情况：</td>
                    <td v-if="itm.number !== 0" style="color: red">选错{{ itm.number }}处</td>
                    <td v-if="itm.number == 0" style="color: lightgreen">完全正确</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="td-content">
                      <table style="width: 100%">
                        <tr>
                          <td colspan="5" v-if="itm.number !== 0">本题题目解读，正确的是？</td>
                          <td v-if="itm.number !== 0">正确答案</td>
                          <td v-if="itm.number !== 0">你的选择</td>
                        </tr>
                        <tr v-for="(it, index) in itm.optionData" :key="index">
                          <td style="width: 20px">{{ it.optionSN }}</td>
                          <td colspan="4" v-html="it.option"></td>
                          <td
                            :class="
                                    it.isCorrect !== it.Np
                                      ? 'css_option_no'
                                      : 'css_option'
                                  "
                            style="text-align: center"
                          >{{ moreOption[it.isCorrect] }}</td>
                          <td
                            :class="
                                    it.isCorrect !== it.Np
                                      ? 'css_option_no'
                                      : 'css_option'
                                  "
                            style="text-align: center"
                          >{{ moreOption[it.Np] }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import htmlToPdf from '../../../util/htmlToPdf'
export default {
  props: {
    AllTable: {
      type: Array
    },
    wrongClassRoom: {
      type: String
    },
    wrongTime: {
      type: String
    },
    show_loading: {
      type: Boolean
    }
  },
  data () {
    return {
      tatleMore: [],
      table: [],
      allWrongClassRoom: '',
      allWrongTime: '',
      wrongTitle: "错题簿",
      moreOption: ["×", "√"],
      frequency: {
        1: "一次做错",
        2: "二次做错",
      },
      questionType: {
        2: "单选",
        3: "多选",
      },
      fullscreenLoading: false

    }
  },
  watch: {
    show_loading (val, old) {
      if (val !== old) {
        if (val == true) {
          console.log(val)
          this.fullscreenLoading = val
        }
      }
    },
    AllTable (val, old) {
      if (val !== old) {
        // console.log(val)
        let arr = []
        val.forEach(item => {
          let obj = {
            userId: null,
            tatleMore: []
          }
          if (arr.length == 0) {
            obj.userId = item.userId
            obj.tatleMore.push(item)
            arr.push(obj)
          } else {
            let user = arr.find(find_item => { return find_item.userId == item.userId })
            if (user) {
              user.tatleMore.push(item)
            } else {
              obj.userId = item.userId
              obj.tatleMore.push(item)
              arr.push(obj)
            }
          }
        });
        this.table = arr
        console.log(this.$refs)
        let number = 0
        setTimeout(() => {
          this.table.forEach((item, index) => {
            htmlToPdf.downloadPDF(document.querySelector(`#exportAllPdf${index}`), item.tatleMore[0].fullName)
            number = number + 1
          })
        }, 10000);

        // this.tatleMore = val
        // // console.log(this.tatleMore)
        // // this.fullscreenLoading = true
        // setTimeout(() => {
        //   // this.$PDFSave(this.$refs.exportAllPdf, this.wrongTitle);

        //   htmlToPdf.downloadPDF(document.querySelector('#exportAllPdf'), this.wrongTitle)
        //   //   this.$message.success('导出成功!如未生成pdf文件请稍等!')
        //   //   // this.fullscreenLoading = false
        // }, 10000);
      }
    },
    wrongClassRoom (val, old) {
      if (val !== old) {
        this.allWrongClassRoom = val
      }
    },
    wrongTime (val, old) {
      if (val !== old) {
        this.allWrongTime = val
      }
    }
  },
  components: {},

  methods: {}
}

</script>
<style lang='less' scoped>
@import '../../../style/wrongBook/index.less';
.css_option {
  color: lightgreen;
  width: 100px;
  text-align: center;
}
.css_option_no {
  color: red;
  width: 100px;
  background-color: yellow;
  text-align: center;
}
.serial_option {
  width: 10px;
}
</style>
